<template>
  <div class="minbox">
    <div class="boxContent">
      <!-- 头部导航区域 -->
      <div class="navbar ">
        <Navbar :itemIndex='activeIndex' />
      </div>
    </div>
    <!-- banner区域 -->
    <div class="boxContentBg">
      <div class="banner">
        <div class="bannerTitle">
          区块链金融服务平台（PaaS）
        </div>
        <div class="seeMore">
          <div>以服务于 F2F 与 F2B 的交易 </div>
          <div>打造基于区块链的安全交易网络平台服务</div>
        </div>
      </div>
    </div>
    <!-- 就业机会 -->
    <div class="boxContentTrem">
      <div class="opportunity">
        <div class="opportunityTitle">
          行业机会
        </div>
        <div class="opportunityDescride">
          区块链技术被认为是继蒸汽机、电力、互联网之后，下一代颠覆性的核心技术。
          如果说蒸汽机释放了人们的生产力，电力解决了人们基本的生活需求，互联网彻底改变了信息传递的方式，那么区块链作为构造信任的机器，将可能彻底改变整个人类社会价值传递的方式。区块链的设计是一种保护措施，比如（应用于）高容错的分布式计算机系统。区块链使混合一致性成为可能。区块链适合记录事件.标题.医疗记录和其他需要收录数据的活动.身份识别管理，交易流程管理和出处证明管理。区块链对于金融脱媒有巨大的潜能，对于引领全球贸易有着巨大的影响。
        </div>
        <div class="opportunityContnet">
          <div class="opportunityContnetItem">
            <div class="opportunityContnetItemImage">
              <img
                src="../../assets/platformServices/blockchain_industry_01@2x.png"
                alt=""
              />
            </div>
            <div class="opportunityContnetItemTitle">去中心化</div>
            <div class="opportunityContnetTrem">
              没有中心服务器，所有节点权限对等，数据采分布式存储，所有节点共同维护链中信息，基于密码学原理及共识算法。
            </div>
          </div>
          <div class="opportunityContnetItem">
            <div class="opportunityContnetItemImage">
              <img
                src="../../assets/platformServices/blockchain_industry_02@2x.png"
                alt=""
              />
            </div>
            <div class="opportunityContnetItemTitle">开放系统</div>
            <div class="opportunityContnetTrem">
              系统对所有参与者开放，参与者可通过公开接口查询数据；数据是参与交易的集体进行维护的，任何交易双方能够直接进行交易，无需第三方介入。
            </div>
          </div>
          <div class="opportunityContnetItem">
            <div class="opportunityContnetItemImage">
              <img
                src="../../assets/platformServices/blockchain_industry_03@2x.png"
                alt=""
              />
            </div>
            <div class="opportunityContnetItemTitle">不可窜改</div>
            <div class="opportunityContnetTrem">
              链中保存完整的数据索引，并与数据内容相关联；修改历史数据会影响到新数据内容，因此修改一个节点的数据无意义。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 对话 dialogue  -->
    <div class="boxContentDialogue">
      <div class="dialogue">
        <div class="expertDialogue">
          您是否希望和我们的专家对话？
        </div>
        <div class="technicalProposal">
          联系我们获得更多技术解决方案
        </div>
        <div class="contactUs">
          <div class="contactUsTitle">联系我们</div>
          <div class="contactUsImages">
            <img
              src="../../assets/homePageImg/home_banner_more@2x.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 底部通用 -->
    <div class="boxContentFooter">
      <FooterAll />
    </div>
  </div>
</template>

<script>
import Navbar from '../../components/public/NavBar'
import FooterAll from '../../components/public/footerAll'
export default {
  data() {
    return {
    }
  },
  components: {
    Navbar,
    FooterAll,
  },
  created () {
    this.activeIndex = this.$route.query.id
  }
}
</script>
<style lang="scss" scoped>
@import "./currency.scss";
</style>
